$(function () {
    var tl = gsap.timeline();
    tl.pause();

    tl
        .to(".cereal", {
            duration: 0.3,
            opacity: 1,
            delay: 2.6,
            stagger: 0.08
        })
        .to(".cereal", {
            duration: 0.3,
            opacity: 0,
            stagger: 0.08
        }, "<0.8")
        .to(".index-logo, .index-product", {
            duration: 1,
            opacity: 0,
            onComplete: () => {
                window.location.href = './list.html'
            }
        }, "<2.5");

    $(window).on('allLoaded', function () {
        if ($('body').hasClass('-isPc') || $('body').hasClass('-wrongBrowser')) {
            return;
        }
        tl.play();
    });
});